export const getDayMonthDateFromUnix = (unixtimestamp) => {
    if (!unixtimestamp)
        return '';
    var date_format = new Date(unixtimestamp * 1000);
    var months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    return `${date_format.getDate()} ${months[date_format.getMonth()]}`;
};

export const getDayMonthHourDateFromUnix = (unixtimestamp) => {
    if (!unixtimestamp)
        return '';
    var date_format = new Date(unixtimestamp * 1000);
    var months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    return `${date_format.getDate()} ${months[date_format.getMonth()]} ${date_format.getHours()}h`;
};

export const getShortDateFromUnix = (unixtimestamp) => {
    if (!unixtimestamp)
        return '';
    var date_format = new Date(unixtimestamp * 1000);
    var months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    return `${date_format.getDate()} ${months[date_format.getMonth()]}, ${date_format.getFullYear()}`;
}

export const getDateFromUnix = (date) => {
    var date_format = new Date(date)
    var months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    return `${date_format.getDate()} de ${months[date_format.getMonth()]} de ${date_format.getFullYear()}`;
  }
