import React from "react";

import { Row, Col } from "reactstrap";


function Spinner(props) {
    return (
        <>
            <Row className="spinner">
                <Col xs="12">
                    <Row>
                        <i className="fa-8x fas fa-spinner fa-spin"></i>
                    </Row>
                    <span className="fa-2x">{props.text}</span>
                </Col>
            </Row>
        </>
    );
}

export default Spinner;