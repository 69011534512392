import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axios from 'axios'
import moment from 'moment'
import { Card, CardHeader, CardBody, CardTitle, Row, Col, FormGroup, } from "reactstrap";
import ReactDatetime from "react-datetime";
import "react-datetime/css/react-datetime.css"
import 'moment/locale/pt-br';


let ndvichart_options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: false,
    position: "nearest",
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: 1,
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a",
          max: 1,
          min: 0,
          autoSkip: true,
          maxTicksLimit: 5,
          beginAtZero: true,
        },
      },
    ],
  },
};

let ndvichart = {
  data: (canvas) => {
    return {
      labels: [],
      datasets: [],
    };
  },
  options: ndvichart_options,
};

function LayerGraph(props) {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [bigChartData, setbigChartData] = React.useState("data");
  const [fromDate, setFromDate] = useState(moment().subtract('months', 2));
  const [toDate, setToDate] = useState(moment());
  const polyId = props.polyId;

  const getData = () => {
    axios.get(`https://api.agromonitoring.com/agro/1.0/ndvi/history?polyid=${polyId}&start=${fromDate.unix()}&end=${toDate.unix()}&appid=${props.apiid}`)
      .then(res => {
        let labels = [];
        let maxData = [];
        let meanData = [];
        let minData = [];
        // res.data = res.data.filter(i => i.type === 's2');
        res.data.forEach(element => {
          var date = new Date(element.dt * 1000);
          var months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
          labels.push(`${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`)
          maxData.push(element.data.max.toFixed(2));
          meanData.push(element.data.mean.toFixed(2));
          minData.push(element.data.min.toFixed(2));
        });
        labels = labels.reverse();
        maxData = maxData.reverse();
        meanData = meanData.reverse();
        minData = minData.reverse();
        ndvichart = {
          data: (canvas) => {
            let ctx = canvas.getContext("2d");
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
            gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
            gradientStroke.addColorStop(0, "rgba(29,140,248,0)");
            return {
              labels: labels,
              datasets: [
                {
                  label: "Máximo",
                  fill: 1,
                  backgroundColor: gradientStroke,
                  borderColor: "#1f8ef1",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: "#1f8ef1",
                  pointBorderColor: "rgba(255,255,255,0)",
                  pointHoverBackgroundColor: "#1f8ef1",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: maxData,

                },
                {
                  label: "Média",
                  fill: 1,
                  backgroundColor: gradientStroke,
                  borderColor: "#72BF24",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: "#72BF24",
                  pointBorderColor: "rgba(255,255,255,0)",
                  pointHoverBackgroundColor: "#72BF24",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: meanData,
                },
                {
                  label: "Mínimo",
                  fill: 1,
                  backgroundColor: gradientStroke,
                  borderColor: "#1f8ef1",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: "#1f8ef1",
                  pointBorderColor: "rgba(255,255,255,0)",
                  pointHoverBackgroundColor: "#1f8ef1",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: minData,
                },
              ],
            };
          },
          options: ndvichart_options,
        };
        forceUpdate()
      })
  }

  useEffect(() => {
    if (polyId == null || polyId === '') {
      return;
    }
    getData();
  }, [polyId]);

  useEffect(() => {
    getData();
  }, [toDate, fromDate]);

  return (
    <>
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">Histórico</h5>
                  <CardTitle tag="h2">NDVI</CardTitle>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <ReactDatetime
                      value={fromDate}
                      dateFormat="DD MMM YY"
                      onChange={(e) => setFromDate(e)}
                      inputProps={{
                        className: "form-control",
                        placeholder: "Data inicial"
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <ReactDatetime
                      value={toDate}
                      dateFormat="DD MMM YY"
                      onChange={(e) => setToDate(e)}
                      inputProps={{
                        className: "form-control",
                        placeholder: "Data final"
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                <Line
                  data={ndvichart[bigChartData]}
                  options={ndvichart.options}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default LayerGraph;
