import React, { useState } from "react";
import "react-datetime/css/react-datetime.css"
import { Bar } from "react-chartjs-2";
import 'moment/locale/pt-br';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";
import { getDayMonthDateFromUnix, getDayMonthHourDateFromUnix } from '../utils/utils.js';
import { none } from "ol/centerconstraint";


function ForecastLayer(props) {
  const [isDaily, setIsDaily] = useState(true);
  let chart = {
    data: canvas => {
      let ctx = canvas.getContext("2d");
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)");
      return {
        data: props.data?.daily,
        labels: isDaily ? props.data?.daily.map(
          d => [getDayMonthDateFromUnix(d.dt), d.rain ? d.rain + 'mm' : '0.00mm'])
          : props.data?.hourly?.map(
            h => [getDayMonthHourDateFromUnix(h.dt), h.rain ? h.rain['1h'] + 'mm' : '0.00mm']),
        datasets: [
          {
            label: "Precipitação",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: "#1d8cf8",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: isDaily ? props.data?.daily.map(
              d => d.rain ? d.rain + Math.min.apply(Math, props.data?.daily.map(d1 => d1.temp.min - 273.15)) : 0.00) :
              props.data?.hourly.map(
                h => h.rain ? (h.rain['1h'] + Math.min.apply(Math, props.data?.hourly.map(h1 => h1.temp - 273.15))) : 0.00),
            order: 0,
          },
          {
            label: "Temperatura",
            type: 'scatter',
            fill: '+1',
            showLine: true,
            backgroundColor: gradientStroke,
            borderColor: "#72BF24",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#72BF24",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#72BF24",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: isDaily ? props.data?.daily.map(d => d.temp.max - 273.15)
              : props.data?.hourly.map(h => h.temp - 273.15),
            order: 1,
          },
          {
            label: "Temperatura mínima",
            type: 'scatter',
            showLine: true,
            fill: false,
            display: none,
            backgroundColor: gradientStroke,
            borderColor: "#72BF24",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#72BF24",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#72BF24",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: isDaily ? props.data?.daily.map(d => d.temp.min - 273.15) : [],
            order: 2,
          }
        ]
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        callbacks: {
          beforeTitle: function (t, d) {
            return t[0]?.xLabel[0]//isDaily ? props.data?.daily.map(d => getDayMonthDateFromUnix(d.dt)) : props.data?.hourly.map(h => getDayMonthDateFromUnix(h.dt))
          },
          title: function () { },
          label: function (t, d) {
            if (t.datasetIndex < 1) {
              return 'Precipitação: ' + (t.yLabel -
                Math.min.apply(Math, isDaily ? props.data?.daily.map(d => d.temp.min - 273.15) : props.data?.hourly.map(h => h.temp - 273.15))
              ).toFixed(2) + 'mm';
            } else if (t.datasetIndex === 1) {
              return (isDaily ? 'Temp. máxima: ' : 'Temperatura: ') + t.yLabel.toFixed(1) + 'º';
            } else {
              return 'Temp. minima: ' + t.yLabel.toFixed(1) + 'º';
            }
          },
          afterBody: function (t, d) { },
        }
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
              max: Math.max.apply(Math, isDaily ? props.data?.daily.map(d => d.temp.max - 273.15) : props.data?.hourly.map(h => h.temp - 273.15)) + 1,
              min: Math.min.apply(Math, isDaily ? props.data?.daily.map(d => d.temp.min - 273.15) : props.data?.hourly.map(h => h.temp - 273.15)) - 1,
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e"
            }
          }
        ]
      }
    }
  };

  const onHourlyData = () => {
    setIsDaily(false);
  }

  const onDailyData = () => {
    setIsDaily(true);
  }

  return (
    <>
      <Card className="card-chart">
        <CardHeader className="row">
          <div className="col-md-3">
            <h5 className="card-category">{isDaily ? 'Diária' : 'Horária'}</h5>
            <CardTitle tag="h3">
              <i className="tim-icons  icon-chart-bar-32 text-primary" />
              Previsâo
            </CardTitle>
          </div>
          <div className="col-md-3 ml-auto">
            <div data-toggle="buttons" role="group" className="btn-group-toggle float-right btn-group">
              <label className={"btn-simple btn btn-github btn-sm" + (isDaily ? '' : ' active')} onClick={onHourlyData} >Previsão horária</label>
              <label className={"btn-simple btn btn-github btn-sm" + (isDaily ? ' active' : '')} onClick={onDailyData}>Previsão diária</label>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Bar
              data={chart.data}
              options={chart.options}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );

}

export default ForecastLayer;
