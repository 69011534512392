import React from "react";
import {
  Row,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import ReactBSAlert from "react-bootstrap-sweetalert";
import "react-datetime/css/react-datetime.css";
import ReactDatetime from "react-datetime";
import * as turf from "@turf/turf";
import axios from "axios";
import moment from "moment";
import Spinner from "components/Spinner/Spinner";
import * as XLSX from "xlsx";

import MapboxGLButtonControl from "./MapboxGLButtonControl";
import LayerGraph from "./LayerGraph";
import ForecastLayer from "./ForecastLayer";
import { getShortDateFromUnix, getDateFromUnix } from "../utils/utils.js";

mapboxgl.accessToken =
  "pk.eyJ1Ijoicmlja3NhIiwiYSI6ImNrdG5pYmQ0dDAyOHQyb28ybnJjZTdnY2cifQ.7bNStnhwmc6O3lEQH7xQfw";

export default class Map extends React.PureComponent {
  state = {
    polygons: [],
    filteredPolygons: [],
    satImages: [],
    stats: { date: "" },
    tiles: {},
    statsSatelliteType: "",
    currentPolygon: {},
    statsData: {},
    imageData: {},
    statsDataType: "",
    polygonsArea: 0.0,
    isHoverPolygon: false,
    selectedPolygon: "",
    isSat: false,
    isTemp: false,
    openCalendar: false,
    loading: false,
    soil: {
      dt: 0,
      moisture: 0,
      t0: 0,
      t10: 0,
    },
    weather: {
      dt: 0,
      weather: [
        {
          id: 0,
          main: "",
          description: "",
          icon: "",
        },
      ],
      main: {
        temp: 0,
        feels_like: 0,
        temp_min: 0,
        temp_max: 0,
        pressure: 0,
        humidity: 0,
        sea_level: 0,
        grnd_level: 0,
      },
      wind: {
        speed: 0,
        deg: 0,
        gust: 0,
      },
      clouds: {
        all: 0,
      },
    },
    polygon: {
      id: "",
      name: "",
      created_at: "",
      area: "",
    },
    currentPage: 0,
    pageSize: 1,
    pageCount: 1,
  };
  map = {};
  apiid = "";
  apiidOpenWeather = "11b80f9d667ac8b457b78354a162fef2";

  constructor(props) {
    super(props);
    this.state = {
      lng: -49.81,
      lat: -20.33,
      zoom: 1,
      stats: { date: "" },
      tiles: {},
      statsDataType: "Estatística",
      openCalendar: false,
      currentPage: 0,
      pageSize: 1,
    };
    this.manageToken();
    this.mapContainer = React.createRef();
    this.filterList = this.filterList.bind(this);
  }

  manageToken() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.has("t")) {
      this.apiid = params.get("t");
      window.sessionStorage.setItem("t", this.apiid);
      window.location.replace(window.location.origin);
    } else if (window.sessionStorage.getItem("t")) {
      this.apiid = window.sessionStorage.getItem("t");
    } else {
      window.location.replace("https://spoteye.com.br");
    }
  }

  componentDidMount() {
    const { lng, lat, zoom } = this.state;
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      attributionControl: false,
      center: [lng, lat],
      zoom: zoom,
    });
    this.map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
        showZoom: true,
        visualizePitch: true,
      })
    );
    this.setState({ loading: true });
    //axios.get(`https://api.agromonitoring.com/agro/1.0/polygons?appid=${this.apiid}`)
    axios
      .get(`https://api.spoteye.com.br/polygon?api_key=${this.apiid}`, {
        headers: {
          Authorization: "token ab73222c7d065203ae2d1f69b9a313b7c73102c9",
        },
        params: {
          limit: 500,
          offset: 0,
        },
      })
      .then((res) => {
        this.setState({
          loading: false,
          pageSize: 15,
          pagesCount: Math.ceil(res.data.count / 15),
          currentPage: 0,
        });
        const polygons = res.data.results;
        polygons.sort(function (p1, p2) {
          return p2.created_at - p1.created_at;
        });
        this.setState({ polygons });
        this.setState({
          filteredPolygons: polygons.slice(
            this.state.currentPage * this.state.pageSize,
            (this.state.currentPage + 1) * this.state.pageSize
          ),
        });
        this.calculeAndSetArea();
        this.generateMiniCanvasFromPolygons();
        const center = new mapboxgl.LngLat(lng, lat);
        this.map.setCenter(center);
        this.loadPolygonsOnMap();
        if (res.data.next) {
          this.getNextPolygons(res.data.next);
        }
      });
    function onResize() {
      this.fitBounds();
    }
    const ctrlResize = new MapboxGLButtonControl({
      className: "mapboxgl-ctrl-resize",
      title: "Resize",
      eventHandler: onResize.bind(this),
    });
    this.map.addControl(ctrlResize, "top-right");
    this.map.addControl(new mapboxgl.FullscreenControl(), "bottom-right");
  }

  getNextPolygons(next) {
    axios
      .get(next.replace('http://', 'https://'), {
        headers: {
          Authorization: "token ab73222c7d065203ae2d1f69b9a313b7c73102c9",
        },
      })
      .then((r) => {
        if (r.data.next) {
          this.getNextPolygons(r.data.next);
        }
        let p = this.state.polygons.concat(r.data.results);
        this.setState({ polygons: p });
        p.sort(function (p1, p2) {
          return p2.created_at - p1.created_at;
        });
        this.setState({
          filteredPolygons: p.slice(
            this.state.currentPage * this.state.pageSize,
            (this.state.currentPage + 1) * this.state.pageSize
          ),
        });
        this.calculeAndSetArea();
        this.generateMiniCanvasFromPolygons();
        this.loadPolygonsOnMap();
      });
  }

  showAllPolygons() {
    this.setState({
      selectedPolygon: "",
      isSat: false,
      isTemp: false,
    });
    this.fitBounds();
  }

  showSat() {
    this.setState({
      isSat: true,
      isTemp: false,
    });
  }

  showTemp() {
    this.setState({
      isSat: false,
      isTemp: true,
    });
  }

  fitBounds() {
    this.map.fitBounds(turf.bbox(this.getGeoJson().data), {
      padding: { top: 30, bottom: 30, left: 30, right: 30 },
    });
  }

  loadPolygonsOnMap() {
    if (this.map.getLayer("polygon")) {
      this.map.removeLayer("polygon");
    }
    if (this.map.getLayer("polygons")) {
      this.map.removeLayer("polygons");
    }
    if (this.map.getLayer("outline")) {
      this.map.removeLayer("outline");
    }
    if (this.map.getLayer("points")) {
      this.map.removeLayer("points");
    }
    if (this.map.getLayer("cluster-count")) {
      this.map.removeLayer("cluster-count");
    }
    if (this.map.getLayer("clusters")) {
      this.map.removeLayer("clusters");
    }
    if (this.map.getSource("polygons")) {
      this.map.removeSource("polygons");
    }
    if (this.map.getSource("points")) {
      this.map.removeSource("points");
    }
    this.map.addSource("polygons", this.getGeoJson());
    this.map.addSource("points", this.getPointsGeoJson());
    this.map.addLayer({
      id: "polygon",
      type: "fill",
      source: "polygons",
      layout: {},
      paint: {
        "fill-color": "#1d8cf8",
        "fill-opacity": 0.5,
      },
    });
    this.map.addLayer({
      id: "outline",
      type: "line",
      source: "polygons",
      layout: {},
      paint: {
        "line-color": "#1d8cf8",
        "line-width": 3,
      },
    });
    this.map.addLayer({
      id: "clusters",
      type: "circle",
      source: "points",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#51bbd6",
          100,
          "#f1f075",
          750,
          "#f28cb1",
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      },
    });
    this.map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "points",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });
    this.fitBounds();
    this.map.on("click", "polygon", (e) => {
      this.map.fitBounds(turf.bbox(e.features[0].geometry), {
        padding: { top: 30, bottom: 30, left: 30, right: 30 },
      });
      this.setState({
        isSat: true,
        selectedPolygon: e.features[0].properties.id,
        currentPolygon: e.features[0],
      });
      this.getAndBuildSatelliteImages(e.features[0].properties.id);
      this.getSoilData(this.state.selectedPolygon);
      this.getWeatherData();
    });
    this.map.on("mouseenter", "polygon", (e) => {
      if (!this.state.isTemp) {
        this.map.getCanvas().style.cursor = "pointer";
        this.setState({ isHoverPolygon: true });
        this.setState({
          polygon: {
            id: e.features[0].properties.id,
            name: e.features[0].properties.name,
            created_at: e.features[0].properties.created_at,
            area: e.features[0].properties.area,
          },
        });
        this.miniCanvas(
          e.features[0].properties.id,
          e.features[0].geometry.coordinates[0]
        );
      }
    });
    this.map.on("mouseleave", "polygon", () => {
      this.map.getCanvas().style.cursor = "";
      this.setState({
        isHoverPolygon: false,
        polygon: {
          id: "",
          name: "",
          created_at: "",
          area: "",
        },
      });
    });
    this.map.on("mouseenter", "clusters", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "clusters", () => {
      this.map.getCanvas().style.cursor = "";
    });
    this.map.on("click", "clusters", (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      });
      const clusterId = features[0].properties.cluster_id;
      this.map
        .getSource("points")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;
          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });
  }

  getPointsGeoJson() {
    let geojson = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: true,
      clusterMinPoints: 1,
    };
    this.state.polygons.forEach((item) => {
      geojson.data.features.push({
        type: "Feature",
        properties: {
          id: item.id,
          name: item.name,
          area: item.area,
          created_at: item.created_at,
        },
        geometry: {
          type: "Point",
          coordinates: item.center,
        },
      });
    });
    return geojson;
  }

  getGeoJson() {
    let geojson = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    };
    this.state.polygons.forEach((item) => {
      geojson.data.features.push({
        type: "Feature",
        properties: {
          id: item.id,
          name: item.name,
          area: item.area,
          created_at: item.created_at,
        },
        geometry: {
          type: "Polygon",
          coordinates: item.geo_json.geometry.coordinates,
        },
      });
    });
    return geojson;
  }

  calculeAndSetArea() {
    let polygonsArea = 0;
    this.state.polygons.forEach((item) => {
      polygonsArea += item.area;
    });
    polygonsArea = parseFloat(polygonsArea).toFixed(2);
    this.setState({ polygonsArea });
  }

  getOperationName(operation) {
    return operation;
    //return operation ? JSON.parse(operation).code : ''
  }

  generateMiniCanvasFromPolygons() {
    this.state.filteredPolygons.forEach((item) => {
      this.miniCanvas(item.name, item.geo_json.geometry.coordinates[0]);
    });
  }

  miniCanvas(refCanvas, coordinates) {
    const canvas = this.refs[refCanvas];
    const ctx = canvas.getContext("2d");
    var myPoints = coordinates;
    var minX, minY, maxX, maxY;
    myPoints.forEach((p, i) => {
      if (i === 0) {
        minX = maxX = p[0];
        minY = maxY = p[1];
      } else {
        minX = Math.min(p[0], minX);
        minY = Math.min(p[1], minY);
        maxX = Math.max(p[0], maxX);
        maxY = Math.max(p[1], maxY);
      }
    });
    const mapWidth = maxX - minX;
    const mapHeight = maxY - minY;
    const mapCenterX = (maxX + minX) / 2;
    const mapCenterY = (maxY + minY) / 2;
    const scale = Math.min(canvas.width / mapWidth, canvas.height / mapHeight);
    ctx.beginPath();
    myPoints.forEach((p) => {
      ctx.lineTo(
        (p[0] - mapCenterX) * scale + canvas.width / 2,
        (p[1] - mapCenterY) * scale + canvas.height / 2
      );
    });
    ctx.strokeStyle = "rgba(255, 255, 255, 0.7)";
    ctx.stroke();
  }

  filterList(e) {
    var filteredPolygons = this.state.polygons;
    filteredPolygons = filteredPolygons.filter(function (item) {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({
      filteredPolygons: filteredPolygons,
      pageSize: 15,
      pagesCount: Math.ceil(this.state.polygons.length / 15),
      currentPage: 0,
    });
    this.generateMiniCanvasFromPolygons();
  }

  downloadPolygons(e) {
    var wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(this.state.polygons);
    XLSX.utils.book_append_sheet(wb, ws, "planilha");
    XLSX.writeFile(wb, "poligonos.xlsx");
  }

  selectPolygon(polygon) {
    this.map.fitBounds(turf.bbox(polygon.geo_json.geometry), {
      padding: { top: 30, bottom: 30, left: 30, right: 30 },
    });
    this.setState({
      selectedPolygon: polygon.id,
      currentPolygon: polygon,
    });
    this.getAndBuildSatelliteImages(polygon.id);
    this.getSoilData(this.state.selectedPolygon);
    this.getWeatherData();
  }

  getSoilData(polyId) {
    axios
      .get(
        `https://api.agromonitoring.com/agro/1.0/soil?polyid=${polyId}&appid=${this.apiid}`
      )
      .then((res) => {
        this.setState({
          soil: {
            dt: res.data.dt,
            moisture: res.data.moisture.toFixed(1) * 100,
            t0: (res.data.t0 - 273.15).toFixed(1),
            t10: (res.data.t10 - 273.15).toFixed(1),
          },
        });
      });
  }

  getWeatherData(pol = false) {
    let lat,
      lon = 0;
    if (this.state.currentPolygon) {
      lon = this.state.currentPolygon._geometry
        ? this.state.currentPolygon._geometry.coordinates[0][0][0]
        : this.state.currentPolygon.center[0];
      lat = this.state.currentPolygon._geometry
        ? this.state.currentPolygon._geometry.coordinates[0][0][1]
        : this.state.currentPolygon.center[1];
    } else if (pol) {
      lon = pol.geo_json.geometry
        ? pol.geo_json.geometry.coordinates[0][0][0]
        : pol.geo_json.geometry.center[0];
      lat = pol.geo_json.geometry
        ? pol.geo_json.geometry.coordinates[0][0][1]
        : pol.geo_json.geometry.center[1];
    }
    axios
      .get(
        `https://api.agromonitoring.com/agro/1.0/weather?lat=${lat}&lon=${lon}&appid=${this.apiid}&lang=pt`
      )
      .then((res) => {
        const weather = res.data;
        weather.main.temp = (res.data.main.temp - 273.15).toFixed(1);
        weather.weather[0].description = res.data.weather[0].description;
        this.setState({
          weather,
        });
        this.getOneCallData(lon, lat);
      });
  }

  getOneCallData(lon, lat) {
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&appid=${this.apiidOpenWeather}&lang=pt`
      )
      .then((res) => {
        this.setState({
          oneCallWeatherMap: res.data,
        });
      });
  }

  clearAlert() {
    this.setState({
      alert: null,
    });
  }

  openWeatherAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ marginTop: "20%", width: "80%" }}
          onCancel={() => this.clearAlert()}
          closeOnClickOutside={true}
          showConfirm={false}
        >
          <div>
            <h2 style={{ color: "black" }}>Previsão do tempo</h2>
            <p style={{ color: "black" }}>
              <b>{this.state.oneCallWeatherMap?.alerts[0].event}</b>
            </p>
            <p style={{ color: "black" }}>
              <small>
                {moment
                  .unix(this.state.oneCallWeatherMap?.alerts[0].start)
                  .format("DD/MMM, HH:mm") +
                  " - " +
                  moment
                    .unix(this.state.oneCallWeatherMap?.alerts[0].end)
                    .format("DD/MMM, HH:mm")}
              </small>
            </p>
            <p style={{ color: "black" }}>
              {this.state.oneCallWeatherMap?.alerts[0].description}
            </p>
            <p style={{ color: "black" }}>
              <small>
                {this.state.oneCallWeatherMap?.alerts[0].sender_name}
              </small>
            </p>
            <hr />
          </div>
        </ReactBSAlert>
      ),
    });
  };

  getAndBuildSatelliteImages(polyId) {
    let start = 1262304000;
    let end = moment().unix();
    axios
      .get(
        `https://api.agromonitoring.com/agro/1.0/image/search?start=${start}&end=${end}&polyid=${polyId}&appid=${this.apiid}`
      )
      .then((res) => {
        this.setState({
          satImages: res.data.reverse(),
          stats: Object.assign({ date: res.data[0].dt }, res.data[0].stats),
          tiles: res.data[0].tile,
          statsSatelliteType: res.data[0].type,
        });
        this.loadStats(this.state.stats.ndvi, "NDVI");
        this.loadImage(this.state.tiles.ndvi);
      });
  }

  loadStats(statsUrl, type) {
    axios.get(statsUrl.replace("http", "https")).then((res) => {
      this.setState({
        statsDataType: type,
        statsData: res.data,
      });
    });
  }

  loadImage(imageUrl) {
    if (this.map.getSource("tileSource")) {
      this.map.removeLayer("tileSource");
      this.map.removeSource("tileSource");
    }
    this.map?.addSource("tileSource", {
      type: "raster",
      tiles: [imageUrl.replace("http", "https")],
    });
    this.map.addLayer({
      id: "tileSource",
      source: "tileSource",
      "source-layer": "agromonitoring",
      type: "raster",
    });
  }

  onDropdownItem(e, type) {
    this.loadStats(e, type);
  }

  onSelectImage(e) {
    this.setState({
      stats: Object.assign({ date: e.dt }, e.stats),
      statsSatelliteType: e.type,
    });
    this.loadStats(e.stats.ndvi, "NDVI");
    this.loadImage(e.tile.ndvi);
  }

  renderInput(props, openCalendar, closeCalendar) {
    function clear() {
      props.onChange({ target: { value: "" } });
    }
    return (
      <div className>
        <input style={{ display: "none" }} {...props} />
      </div>
    );
  }

  onOpenCalendar() {
    this.setState({
      openCalendar: !this.state.openCalendar,
    });
  }

  onScrollLeft() {
    document.getElementById("satellite-images").scrollLeft -= 20;
  }

  onScrollRight() {
    document.getElementById("satellite-images").scrollLeft += 20;
  }

  satImagesValidDate(dt) {
    var yesterday = moment().subtract(1, "day");
    return false; //this.state?.satImages.some( day => current.isSame(day.date, "day"))
  }

  handleClick(e, index) {
    e.preventDefault();
    this.setState({
      currentPage: index,
      filteredPolygons: this.state.polygons.slice(
        index * this.state.pageSize,
        (index + 1) * this.state.pageSize
      ),
    });
  }

  handleSelectPolygonClick(polygon) {
    this.setState({
      isSat: true,
      selectedPolygon: polygon.id,
      currentPolygon: polygon.geo_json,
    });
    this.map.fitBounds(turf.bbox(polygon.geo_json), {
      padding: { top: 30, bottom: 30, left: 30, right: 30 },
    });
    this.setState({
      isSat: true,
      selectedPolygon: polygon.id,
      currentPolygon: polygon,
    });
    this.getAndBuildSatelliteImages(polygon.id);
    this.getSoilData(polygon.id);
    this.getWeatherData(polygon);
  }

  render() {
    const infoDataCard = () => {
      if (this.state.isSat) {
        return (
          <div className="small-card mb-5 card">
            <div className="card-header">
              <div className="row">
                <div className="col">
                  <h2 className="card-category">Camada</h2>
                  <div className="row">
                    <div className="col">
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle caret data-toggle="dropdown">
                          {this.state.statsDataType}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            disabled={this.state.statsDataType === "NDVI"}
                            onClick={() =>
                              this.onDropdownItem(this.state.stats.ndvi, "NDVI")
                            }
                          >
                            NDVI
                          </DropdownItem>
                          <DropdownItem
                            disabled={this.state.statsDataType === "EVI"}
                            onClick={() =>
                              this.onDropdownItem(this.state.stats.evi, "EVI")
                            }
                          >
                            EVI
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-2 card-body">
              <div className="chart-area">
                <canvas
                  className="mini-canvas"
                  style={{ display: "none" }}
                  disabled
                  ref={this.state.polygon?.id}
                  width={50}
                  height={50}
                ></canvas>
                <div className="row  row-table">
                  <div className="col">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            {getShortDateFromUnix(this.state.stats?.date)}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>máximo</td>
                          <td>{this.state.statsData?.max.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>mínimo</td>
                          <td>{this.state.statsData?.min.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>média</td>
                          <td>{this.state.statsData?.mean.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>mediana</td>
                          <td>{this.state.statsData?.median.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>desvio</td>
                          <td>{this.state.statsData?.std.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>num</td>
                          <td>{this.state.statsData?.num}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.isTemp) {
        return (
          <div>
            {this.state.alert}
            <div className="card-stats card">
              <div className="card-header">
                <div className="row">
                  <div className="text-left col-9">
                    <h2 className="mb-0 card-title">Tempo</h2>
                  </div>
                  {this.state.oneCallWeatherMap?.alerts && (
                    <div className="text-right col-3">
                      <button
                        type="button"
                        className="btn-simple btn-round btn-icon agro-notification-icon btn btn-secondary"
                        onClick={this.openWeatherAlert}
                      >
                        <i className="fas fa-exclamation"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="pb-2 card-body">
                <div className="chart-area">
                  <div className="mb-2 row">
                    {
                      <div className="col-4">
                        <img
                          src={
                            "https://openweathermap.org/img/wn/" +
                            this.state.weather?.weather[0].icon +
                            "@4x.png"
                          }
                        />
                      </div>
                    }
                    <div className="col-8">
                      <div className="numbers">
                        <h3 className="mb-0 card-title">
                          {this.state.weather?.main.temp}°
                        </h3>
                        <p className="card-category mt-0">
                          {this.state.weather?.weather[0].description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      {/* <p className="my-0">Sem precipitação na hora</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5 card">
              <div className="card-header">
                <div className="row">
                  <div className="text-left col">
                    <h5 className="card-category mb-0">Atual</h5>
                    <h2 className="card-title">Dados do solo</h2>
                  </div>
                </div>
              </div>
              <div className="py-2 overflow-auto card-body">
                <div className="chart-area">
                  <div className="card-category">
                    <div className="row">
                      <div className="col-7">superfície</div>
                      <div className="col-5">
                        <h3 className="text-right mb-2 card-title">
                          {this.state.soil?.t0}°
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-7">profundidade 10cm</div>
                      <div className="col-5">
                        <h3 className="text-right mb-2 card-title">
                          {this.state.soil?.t10}°
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-7">Umidade do solo</div>
                      <div className="col-5">
                        <h3 className="text-right mb-2 card-title">
                          {this.state.soil?.moisture}%
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="card-stats d-none d-lg-block mb-5 card">
            <div className="card-header">
              <div className="row">
                <div className="col">
                  <div className="numbers text-right">
                    <p className="card-category">Polígono</p>
                    <h3 className="card-title">Informação</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <hr></hr>
              <br />
              {!this.state.isHoverPolygon ? (
                <div className="card-category" style={{ height: "120px" }}>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <p>
                          Passe o mouse por cima do polígono para mais
                          informações
                        </p>
                        <p>
                          Clique no polígono para ver detalhes do satélite e
                          meteorologia
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-category" style={{ height: "120px" }}>
                  <div className="row">
                    <div className="col">
                      <div className="numbers d-flex justify-content-between align-itens-center">
                        <h3 className="card-title">
                          {this.getOperationName(this.state.polygon?.name)}
                        </h3>
                        <div className="pb-3 ml-3">
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              overflow: "hidden",
                            }}
                          >
                            <canvas
                              className="mini-canvas"
                              ref={this.state.polygon?.id}
                              width={50}
                              height={50}
                            ></canvas>
                          </div>
                        </div>
                      </div>
                      <div className="numbers d-flex justify-content-between align-itens-center">
                        <span>Área</span>
                        <p className="numbers font-weight-bold">
                          {this.state.polygon?.area}ha
                        </p>
                      </div>
                      <div className="numbers d-flex justify-content-between align-itens-center">
                        <span>Criado</span>
                        <p className="numbers font-weight-bold">
                          {getShortDateFromUnix(this.state.polygon?.created_at)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      }
    };
    return (
      <div className="content">
        {this.state.loading && <Spinner text={"Carregando polígonos ..."} />}
        <Row style={this.state.loading ? { display: "none" } : {}}>
          <div className="col-lg-6">
            <div className="mb-5">
              <div
                ref={this.mapContainer}
                className="map-container map-box-container"
              >
                <div className="satellite-images-container mb-0  card">
                  <div className="p-1 card-body">
                    {this.state.selectedPolygon ? (
                      <div className="satellite-images-list">
                        <button
                          type="button"
                          className="satellite-arrow-button left"
                          onClick={() => this.onScrollLeft()}
                        >
                          <i className="tim-icons icon-double-left"></i>
                        </button>
                        {/* <label className>
                          <div className="info-icon text-center calendar-icon">
                            <i className="tim-icons icon-calendar-60" onClick={() => this.onOpenCalendar()}></i>
                          </div>
                        </label> */}
                        <div
                          id="satellite-images"
                          className="satellite-image-list"
                        >
                          {this.state.satImages?.map((item) => {
                            return (
                              item.cl < 100 && (
                                <button
                                  type="button"
                                  key={item.dt + item.type}
                                  onClick={() => this.onSelectImage(item)}
                                  className={
                                    "satellite-image py-1 px-3" +
                                    (this.state.stats.date +
                                      this.state.statsSatelliteType ===
                                    item.dt + item.type
                                      ? " active"
                                      : "")
                                  }
                                >
                                  <p className="card-category text-left m-0">
                                    {getShortDateFromUnix(item.dt)}
                                  </p>
                                  <div className="card-category horizontal-container m-0">
                                    <i className="fas fa-cloud satellite-icon"></i>
                                    <div className="satellite-text">
                                      {item.cl}%
                                    </div>
                                    <i className="far fa-image satellite-icon"></i>
                                    <div className="satellite-text">
                                      {item.dc}%
                                    </div>
                                  </div>
                                  <hr className="my-1" />
                                  <p className="card-category text-left">
                                    <i className="tim-icons icon-sound-wave satellite-icon"></i>
                                    {item.type}
                                  </p>
                                </button>
                              )
                            );
                          })}
                        </div>
                        <button
                          type="button"
                          className="satellite-arrow-button right"
                          onClick={() => this.onScrollRight()}
                        >
                          <i className="tim-icons icon-double-right"></i>
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <ReactDatetime
                  className="rdt satellite-calendar agro-datepicker"
                  open={this.state.openCalendar}
                  closeOnClickOutside={true}
                  timeFormat={false}
                  isValidDate={this.satImagesValidDate}
                  renderInput={this.renderInput}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">{infoDataCard()}</div>
          <div className="col-sm-6 col-lg-3">
            {this.state.selectedPolygon ? (
              <div className="small-card mb-5 pb-3 card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6 col-sm-8 col-md-9">
                      <h5 className="card-category mb-0">Todos</h5>
                      <h2 className="card-title">Polígonos</h2>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                      <div
                        data-toggle="buttons"
                        role="group"
                        className="btn-group-toggle float-right btn-group"
                      >
                        <label
                          className="btn-simple btn btn-github btn-sm"
                          onClick={() => this.showAllPolygons()}
                        >
                          <span className="fas fa-list"></span>
                        </label>
                        <label
                          className={
                            "btn-simple btn btn-github btn-sm" +
                            (this.state.isSat ? " active" : "")
                          }
                          onClick={() => this.showSat()}
                        >
                          <span className="fas fa-satellite"></span>
                        </label>
                        <label
                          className={
                            "btn-simple btn btn-github btn-sm" +
                            (!this.state.isSat && this.state.isTemp
                              ? " active"
                              : "")
                          }
                          onClick={() => this.showTemp()}
                        >
                          <span className="fas fa-thermometer-half"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-2 overflow-auto card-body">
                  <table className="table">
                    <tbody>
                      {this.state.polygons?.map((item) => {
                        return (
                          <tr
                            onClick={() => this.selectPolygon(item)}
                            key={item.id}
                            className={
                              "clickable-table-row highlight-background" +
                              (this.state.selectedPolygon === item.id
                                ? ""
                                : "highlight-background")
                            }
                          >
                            <td>{item.name}</td>
                            <td className="text-right">
                              {parseFloat(item.area).toFixed(2)}ha
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="card-stats d-none d-lg-block mb-5 card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-4">
                      <div className="info-icon text-center icon-primary">
                        <i className="tim-icons icon-shape-star"></i>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="numbers">
                        <p className="card-category">Estatística</p>
                        <h3 className="card-title">Total</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <hr></hr>
                  <br />
                  <div className="card-category">
                    <div className="row">
                      <div className="col-6">Polígonos</div>
                      <div className="col-6">
                        <h3 className="text-right card-title">
                          {this.state.polygons?.length}
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">Área</div>
                      <div className="col-8">
                        <h3 className="text-right card-title text-lowercase">
                          {this.state.polygonsArea} ha
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Row>
        {!this.state.isSat && !this.state.isTemp ? (
          <Row style={this.state.loading ? { display: "none" } : {}}>
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <div className="horizontal-container justify">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Pesquise por operação"
                      onChange={this.filterList}
                      style={{ maxWidth: "300px" }}
                    />
                    {/* <a href="#"><button className="btn btn-primary" style={{ minWidth: "200px" }}>Criar polígono</button></a> */}
                    <Button
                      color="default"
                      onClick={(e) => this.downloadPolygons(e)}
                    >
                      Download de todos polígonos
                    </Button>
                  </div>
                </div>
                <div className="card-body">
                  <Table className="tablesorter polygon-table" responsive>
                    <thead>
                      <tr>
                        <th></th>
                        <th className="header">Operação</th>
                        <th className="header">Criado</th>
                        <th className="header headerSortUp">Área</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredPolygons?.map((item) => {
                        return (
                          <tr
                            key={item.id + item.created_at}
                            onClick={() => this.handleSelectPolygonClick(item)}
                          >
                            <td>
                              <canvas
                                className="mini-canvas"
                                ref={item.name}
                                width={50}
                                height={50}
                              ></canvas>
                            </td>
                            <td>{this.getOperationName(item.name)}</td>
                            <td>{getDateFromUnix(item.created_at)}</td>
                            <td>{parseFloat(item.area).toFixed(2)}ha</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                {Number(this.state.filteredPolygons?.length ?? 15) >=
                  this.state.pageSize && (
                  <Pagination aria-label="navigation">
                    <PaginationItem disabled={this.state.currentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          this.handleClick(e, this.state.currentPage - 1)
                        }
                        previous
                        href="#"
                      />
                    </PaginationItem>
                    {/* {[...Array(this.state.pagesCount)].map((page, i) =>
                    <PaginationItem active={i === this.state.currentPage} key={i}>
                      <PaginationLink onClick={e => this.handleClick(e, i)} href="#">{i + 1}</PaginationLink>
                    </PaginationItem>
                  )} */}
                    <PaginationItem
                      disabled={
                        this.state.currentPage >= this.state.pagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          this.handleClick(e, this.state.currentPage + 1)
                        }
                        next
                        href="#"
                      />
                    </PaginationItem>
                  </Pagination>
                )}
              </div>
            </div>
          </Row>
        ) : !this.state.isTemp ? (
          <LayerGraph polyId={this.state.selectedPolygon} apiid={this.apiid} />
        ) : (
          <ForecastLayer data={this.state.oneCallWeatherMap} />
        )}
      </div>
    );
  }
}
